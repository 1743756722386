/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  --ratio: 1.14;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.5);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.5);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.5);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.5);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.19;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.24;
  }
}
@-moz-keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes modal--open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes body--modal-open {
  0%, 99% {
    overflow: visible;
    max-height: none;
  }
  100% {
    overflow: hidden;
    max-height: 100vh;
  }
}
@-webkit-keyframes body--modal-open {
  0%, 99% {
    overflow: visible;
    max-height: none;
  }
  100% {
    overflow: hidden;
    max-height: 100vh;
  }
}
@-o-keyframes body--modal-open {
  0%, 99% {
    overflow: visible;
    max-height: none;
  }
  100% {
    overflow: hidden;
    max-height: 100vh;
  }
}
@keyframes body--modal-open {
  0%, 99% {
    overflow: visible;
    max-height: none;
  }
  100% {
    overflow: hidden;
    max-height: 100vh;
  }
}
@-moz-keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-webkit-keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-o-keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@keyframes modal--close {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes global-navigation-display {
  0% {
    top: 105vh;
  }
  100% {
    top: 0;
  }
}
@-webkit-keyframes global-navigation-display {
  0% {
    top: 105vh;
  }
  100% {
    top: 0;
  }
}
@-o-keyframes global-navigation-display {
  0% {
    top: 105vh;
  }
  100% {
    top: 0;
  }
}
@keyframes global-navigation-display {
  0% {
    top: 105vh;
  }
  100% {
    top: 0;
  }
}
@-moz-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes post-content-call-to-action-anchor-interaction {
  0% {
    transform: skew(15deg) translateX(0);
  }
  50% {
    transform: skew(15deg) translateX(0.539333333333333rem);
  }
  100% {
    transform: skew(15deg) translateX(0);
  }
}
@-webkit-keyframes post-content-call-to-action-anchor-interaction {
  0% {
    transform: skew(15deg) translateX(0);
  }
  50% {
    transform: skew(15deg) translateX(0.539333333333333rem);
  }
  100% {
    transform: skew(15deg) translateX(0);
  }
}
@-o-keyframes post-content-call-to-action-anchor-interaction {
  0% {
    transform: skew(15deg) translateX(0);
  }
  50% {
    transform: skew(15deg) translateX(0.539333333333333rem);
  }
  100% {
    transform: skew(15deg) translateX(0);
  }
}
@keyframes post-content-call-to-action-anchor-interaction {
  0% {
    transform: skew(15deg) translateX(0);
  }
  50% {
    transform: skew(15deg) translateX(0.539333333333333rem);
  }
  100% {
    transform: skew(15deg) translateX(0);
  }
}
html * {
  will-change: outline-offset;
  transition-property: outline-offset;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  outline-offset: 0;
}
html[data-focus-source="key"] *:focus,
html[data-focus-source="script"] *:focus {
  outline-offset: 6px;
  outline: 2px dashed #ff6c00;
  overflow: visible;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
html {
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
body {
  will-change: unset;
  transform: none;
  filter: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
}
.navigation_is_open body {
  animation: body--modal-open 0.28s;
  overflow: hidden;
  max-height: 100vh;
}
body.modal_is_visible {
  animation: body--modal-open 0.14s;
  overflow: hidden;
  max-height: 100vh;
}
.main_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1a384f;
}
.page_content {
  flex: 0 0 auto;
  width: 100%;
}
.page_content:last-child {
  flex: 1 1 0;
}
.page_content:last-child .page_content__inner > *:last-child {
  margin-bottom: 3.236rem;
}
.page_content__inner {
  display: flex;
  width: 92%;
  max-width: calc(900px - 3.236rem);
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start;
}
.page_content__inner > *:first-child {
  margin-top: 3.236rem;
}
.has_drop_cap::after {
  content: "";
  display: table;
  clear: both;
}
.has_drop_cap__drop_cap {
  margin-right: 0.269666666666667rem;
  float: left;
  box-shadow: inset 0 -0.1em 0 #fff;
  text-transform: uppercase;
  line-height: 1;
  font-size: 2.8em;
  font-weight: 700;
  color: #436782;
}
img {
  display: block;
  height: auto;
  width: auto;
  max-width: 100%;
  vertical-align: bottom;
}
* + img {
  margin-top: 1.618rem;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
input,
button {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
hr {
  display: none;
}
hr:not(:last-child) {
  margin-bottom: -1.618rem;
}
:root {
  font-size: 13px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.5vw + 10.6px);
  }
}
@media screen and (min-width: 1680px) {
  :root {
    font-size: 19px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea {
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  line-height: 1.618;
  font-size: inherit;
  letter-spacing: 0em;
  color: #fff;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.5;
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  letter-spacing: 0em;
  text-shadow: 0 1px 2px #1a384f;
  color: rgba(255,255,255,0.9);
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #ffc72e;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover {
  color: #ffd561;
}
h1 {
  max-width: 27.5ch;
  font-size: 1.68896016rem;
  font-size: var(--fz-ratio-power--four);
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 2.00533921rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.36421376rem;
    font-size: var(--fz-ratio-power--four);
  }
}
h2 {
  max-width: 27.5ch;
  font-size: 1.481544rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.685159rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 1.906624rem;
    font-size: var(--fz-ratio-power--three);
  }
}
* + h2 {
  margin-top: 2.222316rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.5277385rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 2.859936rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 38.5ch;
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.4161rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + h3 {
  margin-top: 1.9494rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 2.12415rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.3064rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 45ch;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
* + h4 {
  margin-top: 1.71rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.785rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.86rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: 'Cabin', sans-serif;
  font-size: 1rem;
  line-height: 1.618;
  letter-spacing: 0em;
  text-shadow: 0 1px 1px #1a384f;
  color: #fff;
}
* + p {
  margin-top: 1.618rem;
}
h1 + p,
h2 + p,
h3 + p,
h4 + p {
  margin-top: 1.077588rem;
}
.reading_content p,
p.reading_content {
  width: 55ch;
}
.reading_content p > a,
p.reading_content > a,
.reading_content p > a:visited,
p.reading_content > a:visited {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #ffc72e;
}
.reading_content p > a:active,
p.reading_content > a:active,
.reading_content p > a:visited:active,
p.reading_content > a:visited:active,
.reading_content p > a:focus,
p.reading_content > a:focus,
.reading_content p > a:visited:focus,
p.reading_content > a:visited:focus,
.reading_content p > a:hover,
p.reading_content > a:hover,
.reading_content p > a:visited:hover,
p.reading_content > a:visited:hover {
  color: #ffd561;
}
a,
a:visited {
  will-change: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: none;
}
b,
strong {
  font-weight: normal;
  font-family: 'Cabin', sans-serif;
}
i,
em {
  font-style: normal;
  font-family: 'Cabin', sans-serif;
}
::selection {
  background-color: #173145;
  color: #fff;
}
.reading_content {
  width: 100%;
}
* + .reading_content--page_content {
  margin-top: 3.236rem;
}
.reading_content > ul,
.reading_content > ol,
.reading_content > dl {
  display: block;
  width: 100%;
  max-width: 55ch;
  padding-left: 3ch;
  text-shadow: 0 1px 1px #1a384f;
}
.reading_content * + ul,
.reading_content * + ol,
.reading_content * + dl {
  margin-top: 1.077588rem;
}
.reading_content ul {
  list-style-type: disc;
}
.reading_content ul ul {
  list-style-type: circle;
}
.reading_content ul ul ul {
  list-style-type: square;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol {
  list-style-type: lower-roman;
}
.reading_content li {
  max-width: 100%;
}
.reading_content li ul:first-child,
.reading_content li ol:first-child {
  margin-top: 0.809rem;
}
.reading_content > * + li {
  margin-top: 0.809rem;
}
sup {
  vertical-align: super;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_and_local_headers {
  position: relative;
  z-index: auto;
  z-index: 80;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  border-top: 4px solid #1a384f;
  background-color: #436782;
}
@media only screen and (min-width: 481px) {
  .global_and_local_headers {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .global_and_local_headers {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 881px) {
  .global_and_local_headers {
    min-height: 8.668rem;
  }
}
.global_and_local_headers__decorative_vector {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  height: 40rem;
  max-height: none;
  width: auto;
}
.global_footer {
  flex: 0 0 auto;
  margin-top: auto;
  width: 100%;
  background-color: #1a384f;
  padding-bottom: 3.236rem;
}
@media only screen and (min-width: 681px) {
  .global_footer {
    padding-bottom: 0;
  }
}
.global_footer__navigation {
  display: block;
  width: 100%;
}
.global_footer__navigation__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  width: 92%;
  max-width: 1040px;
  border-top: 1px solid #35546b;
}
.global_footer__navigation__item {
  flex: 0 0 33.333333333333336%;
  display: inline-block;
  font-size: 0;
}
.global_footer__navigation__item:nth-child(3n+1) {
  position: relative;
  z-index: auto;
}
.global_footer__navigation__item:nth-child(3n+1)::before {
  content: "";
  position: absolute;
  z-index: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 92vw;
  max-width: 1040px;
  background-color: #35546b;
}
.global_footer__navigation__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.809rem 0.809rem 0.809rem 0;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  color: #ffc72e;
}
@media only screen and (min-width: 681px) {
  .global_footer__navigation__anchor {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .global_footer__navigation__anchor {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.global_footer__navigation__anchor:active,
.global_footer__navigation__anchor:focus,
.global_footer__navigation__anchor:hover {
  color: #ffd561;
}
.global_footer__navigation__anchor__vector {
  height: 0.4045rem;
  width: 1.2rem;
}
.global_footer__navigation__anchor__vector__path {
  fill: #ffc72e;
}
.global_footer__navigation__anchor__text {
  display: inline-block;
}
* + .global_footer__navigation__anchor__text {
  margin-left: 0.539333333333333rem;
}
.global_footer__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 92%;
  max-width: 1040px;
  margin: 1.618rem auto;
  color: #fff;
}
.global_footer__contact,
.global_footer__company_details {
  flex: 1;
}
.global_footer__contact_text {
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .global_footer__contact_text {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .global_footer__contact_text {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.global_footer__contact_text__guidance {
  color: #436782;
}
.global_footer__company_details__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-top: 1px solid #35546b;
  padding-top: 1.618rem;
  font-size: 0.769467528470299rem;
  font-size: var(--fz-ratio-power--minus-two);
  text-transform: uppercase;
  text-align: center;
}
@media only screen and (min-width: 681px) {
  .global_footer__company_details__list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 681px) {
  .global_footer__company_details__list {
    justify-content: center;
  }
}
* + .global_footer__company_details__list {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  .global_footer__company_details__list {
    font-size: 0.706164818868724rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .global_footer__company_details__list {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.global_footer__company_details__item {
  flex: 0 0 auto;
}
.global_footer__company_details__item + .global_footer__company_details__item {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  .global_footer__company_details__item + .global_footer__company_details__item {
    position: relative;
    z-index: auto;
    margin-top: 0;
    margin-left: 1.618rem;
    padding-left: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .global_footer__company_details__item + .global_footer__company_details__item::before {
    content: "•";
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: auto;
    left: 0;
    top: 50%;
    font-size: 1.2996rem;
    font-size: var(--fz-ratio-power--two);
    color: #35546b;
  }
}
@media only screen and (min-width: 681px) and (min-width: 681px) {
  .global_footer__company_details__item + .global_footer__company_details__item::before {
    font-size: 1.4161rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 681px) and (min-width: 1281px) {
  .global_footer__company_details__item + .global_footer__company_details__item::before {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.global_footer__company_name,
.global_footer__company_number,
.global_footer__company_address {
  display: block;
}
* + .global_footer__company_name,
* + .global_footer__company_number,
* + .global_footer__company_address {
  margin-top: 0.539333333333333rem;
}
.global_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 880px) {
  .global_header {
    flex: 0 0 100%;
  }
}
@media only screen and (min-width: 1141px) {
  .global_header {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    justify-content: flex-start;
  }
}
.global_header__logo {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.539333333333333rem;
}
.global_header__logo .uncommon_correlation_logo {
  height: 3.236rem;
  width: 6.408rem;
  max-width: 100%;
}
.global_header__logo .uncommon_correlation_logo__path {
  fill: #fff;
}
.global_header__global_navigation_and_search {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 880px) {
  .global_header__global_navigation_and_search {
    position: fixed;
    bottom: 1.618rem;
    left: 0;
  }
}
@media only screen and (min-width: 881px) {
  .global_header__global_navigation_and_search {
    flex-direction: column;
  }
}
@media only screen and (max-width: 880px) {
  .global_header__global_navigation_and_search {
    justify-content: center;
  }
}
* + .global_header__global_navigation_and_search {
  margin-top: 0.539333333333333rem;
}
@media only screen and (max-width: 880px) {
  .global_header__global_navigation_and_search {
    width: 100%;
  }
}
.global_header__navigation_button {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 0.809rem 1.077588rem;
}
@media only screen and (max-width: 880px) {
  .global_header__navigation_button {
    background-color: #1a384f;
  }
}
.global_header__navigation_button::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 5px;
  right: 2px;
  bottom: 5px;
  left: 2px;
  border: 1px solid #fff;
}
.global_header__navigation_button::after {
  content: "";
  position: absolute;
  z-index: auto;
  top: 0;
  right: 7px;
  bottom: 0;
  left: 7px;
  border: 1px solid #fff;
}
.global_header__navigation_button__text {
  display: block;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0.026em;
  text-shadow: 0 1px 1px #1a384f;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #ffc72e;
}
@media only screen and (min-width: 681px) {
  .global_header__navigation_button__text {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .global_header__navigation_button__text {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_header__navigation_button .vector__triangle_down {
  will-change: transform;
  transition-property: transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  height: 0.3rem;
  width: 0.539333333333333rem;
  margin-left: 0.4045rem;
}
.navigation_is_open .global_header__navigation_button .vector__triangle_down {
  transform: rotate(180deg);
}
.global_header__navigation_button .vector__triangle_down__path {
  fill: #ffc72e;
}
.global_header__navigation_icon__path {
  fill: #35546b;
}
.global_navigation {
  z-index: 90;
  animation: global-navigation-display 0.28s;
  will-change: top;
  transition-property: top;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #35546b;
}
@media only screen and (min-width: 1141px) {
  .global_navigation {
    align-items: flex-start;
  }
}
.global_navigation[hidden] {
  display: none;
  top: 105vw;
  opacity: 0;
}
.global_navigation__inner {
  z-index: 100;
}
@media only screen and (min-width: 1141px) {
  .global_navigation__inner {
    margin-left: auto;
    margin-right: auto;
  }
}
.global_navigation__list {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 28rem;
  max-width: 92vw;
  border: 1px solid #fff;
  border-left: 0;
  padding: 1.618rem 2.695588rem;
}
@media only screen and (min-width: 1141px) {
  .global_navigation__list {
    margin-top: 1.618rem;
  }
}
.global_navigation__list::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
* + .global_navigation__item {
  margin-top: 0.809rem;
}
.global_navigation__anchor {
  position: relative;
  z-index: auto;
  text-shadow: 0 1px 2px #1a384f;
  font-size: 1.481544rem;
  font-size: var(--fz-ratio-power--three);
  color: #ffc72e;
}
@media only screen and (min-width: 681px) {
  .global_navigation__anchor {
    font-size: 1.685159rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  .global_navigation__anchor {
    font-size: 1.906624rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.global_navigation__anchor::before {
  content: "";
  will-change: width;
  transition-property: width;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: inline-block;
  margin-right: 0.539333333333333rem;
  height: 1px;
  width: 1.618rem;
  background-color: #fff;
}
.global_navigation__anchor:active::before,
.global_navigation__anchor:focus::before,
.global_navigation__anchor:hover::before {
  width: 2.427rem;
}
.global_navigation__anchor--current_url {
  color: rgba(255,255,255,0.6);
}
.global_navigation__contact__list {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 28rem;
  max-width: 92vw;
  border: 1px solid #fff;
  border-top: 0;
  border-left: 0;
  padding: 1.618rem 2.695588rem;
}
.global_navigation__contact__list::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.global_navigation__contact__item {
  display: block;
  width: 100%;
}
.global_navigation__contact__heading {
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.026em;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .global_navigation__contact__heading {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .global_navigation__contact__heading {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_navigation__contact__text {
  color: #fff;
}
.global_navigation__contact__text__guidance {
  color: rgba(255,255,255,0.6);
}
.global_navigation__decorative_vector {
  position: absolute;
  z-index: auto;
  bottom: 0;
  left: -0.82rem;
  height: 26rem;
  width: auto;
}
blockquote {
  position: relative;
  z-index: auto;
  display: block;
  margin-left: -4vw;
  width: 55ch;
  max-width: 100%;
  padding: 0.809rem 0 0.809rem 4vw;
}
* + blockquote {
  margin-top: 3.236rem;
}
@media only screen and (min-width: 681px) {
  blockquote {
    margin-left: 0;
  }
}
@media only screen and (max-width: 680px) {
  blockquote {
    box-shadow: inset 2px 0 0 #fff;
  }
}
@media only screen and (min-width: 681px) {
  blockquote {
    padding: 3.236rem 0;
  }
}
blockquote:not(:last-child) {
  margin-bottom: 3.236rem;
}
@media only screen and (min-width: 681px) {
  blockquote::before,
  blockquote::after {
    content: "";
    position: absolute;
    z-index: auto;
    right: 0;
    left: 0;
    height: 4px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
}
@media only screen and (min-width: 681px) {
  blockquote::before {
    top: 0;
  }
}
@media only screen and (min-width: 681px) {
  blockquote::after {
    bottom: 0;
  }
}
blockquote > *:first-child::before {
  content: url("../images/quotes.svg");
  display: block;
  margin: 0 auto 1.077588rem;
  width: 2rem;
}
@media only screen and (min-width: 481px) {
  blockquote > *:first-child::before {
    width: 3rem;
  }
}
blockquote * {
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  font-style: italic;
  color: #436782;
}
@media only screen and (min-width: 681px) {
  blockquote * {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  blockquote * {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
cite {
  display: block;
  margin-top: 0.809rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.026em;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: hsla(205.7,32%,39%,0.8);
}
@media only screen and (min-width: 681px) {
  cite {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  cite {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
cite::before {
  content: "–";
  display: inline-block;
  margin-right: 0.4045rem;
  color: #fff;
}
.definitions {
  width: 100%;
  border-left: 1px solid #fff;
  background-color: rgba(255,255,255,0.5);
  padding: 1.618rem 4vw;
}
* + .definitions {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 481px) {
  .definitions {
    border-left: 2px solid #fff;
  }
}
@media only screen and (min-width: 481px) {
  .definitions {
    padding: 0.809rem 1.618rem 1.2135rem;
  }
}
.definitions__heading {
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  .definitions__heading {
    font-size: 1.4161rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .definitions__heading {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.definitions__list {
  border-top: 1px solid #fff;
  padding-top: 1.077588rem;
}
* + .definitions__list {
  margin-top: 0.539333333333333rem;
}
.definitions__item {
  display: block;
}
* + .definitions__item {
  margin-top: 1.077588rem;
}
.definitions__term {
  font-weight: 700;
}
.definitions__term::after {
  content: ":";
}
.definitions__description {
  position: relative;
  z-index: auto;
  font-style: italic;
}
* + .definitions__description {
  margin-top: 0.809rem;
}
* + .definitions__description {
  margin-left: 1.618rem;
}
@media only screen and (min-width: 481px) {
  * + .definitions__description {
    margin-left: 3.236rem;
  }
}
.definitions__description::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 0.76em;
  left: -1.2135rem;
  background-color: #fff;
  height: 1px;
  width: 1ch;
}
figure {
  display: block;
}
* + figure {
  margin-top: 3.236rem;
}
figcaption {
  position: relative;
  z-index: auto;
  display: inline-block;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  padding: 0.4045rem 0.809rem 0.539333333333333rem;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: #fff;
}
@media only screen and (min-width: 681px) {
  figcaption {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  figcaption {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 681px) {
  figcaption::before {
    content: "";
    position: absolute;
    z-index: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 4px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
}
.footnotes {
  width: 100%;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .footnotes {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  .footnotes {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .footnotes {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.footnotes::before {
  content: "Footnotes";
  display: block;
  width: calc(100% - 0.4045rem);
  box-shadow: 0.4045rem 0.4045rem 0 #fff;
  border: 1px solid #35546b;
  background-color: #436782;
  padding: 1.618rem 1.077588rem 0.809rem;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  text-shadow: 0 1px 2px #1a384f;
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .footnotes::before {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .footnotes::before {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.footnotes ol {
  display: block;
  margin-top: 2.0225rem;
  min-width: 100%;
  padding-left: 0;
}
@media only screen and (min-width: 681px) {
  .footnotes ol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
}
.footnotes li {
  margin-left: 1.2rem;
}
@media only screen and (min-width: 681px) {
  .footnotes li {
    flex: 0 0 calc(50% - 2.009rem);
  }
}
@media only screen and (min-width: 681px) {
  .footnotes li:nth-child(odd) {
    margin-right: 1.618rem;
  }
}
@media only screen and (max-width: 680px) {
  .footnotes li:nth-child(n+2) {
    margin-top: 1.618rem;
  }
}
@media only screen and (max-width: 680px) {
  .footnotes li:nth-child(n+2) {
    border-top: 1px solid #fff;
  }
}
@media only screen and (max-width: 680px) {
  .footnotes li:nth-child(n+2) {
    padding-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .footnotes li:nth-child(n+3) {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .footnotes li:nth-child(n+3) {
    border-top: 1px solid #fff;
  }
}
@media only screen and (min-width: 681px) {
  .footnotes li:nth-child(n+3) {
    padding-top: 1.618rem;
  }
}
.footnotes li::marker {
  color: rgba(255,255,255,0.6);
}
.footnotes a {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  display: block;
  word-break: break-word;
  color: #ffc72e;
}
.footnotes a:active,
.footnotes a:focus,
.footnotes a:hover {
  color: #ffd561;
}
.footnotes a:not([class^="footnote-return"]) {
  margin: 0.4045rem 0;
}
.footnotes__list {
  list-style-position: outside;
  list-style-type: decimal;
  padding-left: 2ch;
}
* + .footnotes__item {
  margin-top: 0.809rem;
}
.footnotes__content {
  max-width: 55ch;
}
.footnotes__content a,
.footnotes__content a:visited {
  color: #ffc72e;
}
.footnotes__content a:active,
.footnotes__content a:visited:active,
.footnotes__content a:hover,
.footnotes__content a:visited:hover,
.footnotes__content a:focus,
.footnotes__content a:visited:focus {
  box-shadow: inset 0 -0.14em 0 #fff;
  color: #ffd561;
}
.footnotes__accessed_on_and_return_to_reference {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footnotes__return_to_reference {
  display: block;
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
}
* + .footnotes__return_to_reference {
  margin-left: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .footnotes__return_to_reference {
    font-size: 1.4161rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .footnotes__return_to_reference {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.footnote-return {
  margin-top: 0.4045rem;
}
.footnote-ref a,
.footnote-ref a::before,
.footnote-ref a::after {
  color: #ffc72e;
}
.footnote-ref a:active,
.footnote-ref a::before:active,
.footnote-ref a::after:active,
.footnote-ref a:focus,
.footnote-ref a::before:focus,
.footnote-ref a::after:focus,
.footnote-ref a:hover,
.footnote-ref a::before:hover,
.footnote-ref a::after:hover {
  color: #ffd561;
}
.footnote-ref a::before,
.footnote-ref a::after {
  display: inline-block;
}
.footnote-ref a::before {
  content: "[";
  margin-right: 0.1618rem;
  margin-left: 0.20225rem;
}
.footnote-ref a::after {
  content: "]";
  margin-left: 0.1618rem;
  margin-right: 0.20225rem;
}
.local_header__breadcrumbs__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.local_header__breadcrumbs__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.2;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  .local_header__breadcrumbs__item {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .local_header__breadcrumbs__item {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.local_header__breadcrumbs__anchor {
  will-change: color;
  transition-property: color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  color: #ffc72e;
}
.local_header__breadcrumbs__anchor:active,
.local_header__breadcrumbs__anchor:focus,
.local_header__breadcrumbs__anchor:hover {
  color: #ffd561;
}
.local_header__breadcrumbs .vector__triangle_down {
  margin-left: 0.3236rem;
  height: 0.539333333333333rem;
  width: 0.539333333333333rem;
}
.local_header__breadcrumbs .vector__triangle_down__path {
  fill: #35546b;
}
.local_header {
  padding: 0.809rem 0;
}
@media only screen and (max-width: 480px) {
  .local_header {
    flex: 1 1 auto;
  }
}
@media only screen and (min-width: 481px) {
  .local_header {
    padding: 1.618rem 0 1.077588rem;
  }
}
.local_header__inner {
  display: flex;
  width: 92%;
  max-width: calc(900px - 3.236rem);
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start;
}
.local_header__heading {
  width: 100%;
  max-width: 22ch;
  line-height: 1.5;
  font-size: 1.68896016rem;
  font-size: var(--fz-ratio-power--four);
  font-weight: 700;
  text-shadow: 0 0 0.06em #1a384f;
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .local_header__heading {
    font-size: 2.00533921rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  .local_header__heading {
    font-size: 2.36421376rem;
    font-size: var(--fz-ratio-power--four);
  }
}
* + .local_header__heading {
  margin-top: 0.4045rem;
}
.local_header__page_metadata__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
* + .local_header__page_metadata__list {
  margin-top: 0.539333333333333rem;
}
.local_header__page_metadata__item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  line-height: 2.2;
}
.local_header__page_metadata__item:not(:last-child) {
  margin-right: 1.618rem;
}
.local_header__page_metadata__term,
.local_header__page_metadata__description {
  flex: 0 0 auto;
  display: block;
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  text-shadow: 0 1px 1px #1a384f;
  color: rgba(255,255,255,0.9);
}
@media only screen and (min-width: 681px) {
  .local_header__page_metadata__term,
  .local_header__page_metadata__description {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .local_header__page_metadata__term,
  .local_header__page_metadata__description {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.local_header__page_metadata__description {
  margin-left: 0.22em;
}
.local_header__summary {
  width: 100%;
}
.local_header__heading + .local_header__summary {
  margin-top: 0.539333333333333rem;
}
.local_header__page_metadata__list + .local_header__summary {
  margin-top: 0.3236rem;
}
* + .local_header__summary {
  border-top: 2px solid #35546b;
  padding-top: 0.809rem;
}
.local_header__summary__text {
  max-width: 31ch;
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
  font-weight: 400;
  line-height: 1.5;
  text-shadow: 0 1px 1px #1a384f;
  color: #fff;
}
@media only screen and (min-width: 681px) {
  .local_header__summary__text {
    font-size: 1.4161rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .local_header__summary__text {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.post_content_call_to_action {
  will-change: background-color, border-color, color;
  transition-property: background-color, border-color, color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  transform: skew(-15deg);
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.8rem;
  border: 1px solid #fff;
  border-left: 0;
  padding: 0.809rem 2.156794rem 0.809rem 2.427rem;
}
* + .post_content_call_to_action {
  margin-top: 3.236rem;
}
.post_content_call_to_action:active .post_content_call_to_action__text,
.post_content_call_to_action:focus .post_content_call_to_action__text,
.post_content_call_to_action:hover .post_content_call_to_action__text {
  color: #ffd561;
}
.post_content_call_to_action:active .triangle,
.post_content_call_to_action:focus .triangle,
.post_content_call_to_action:hover .triangle {
  animation: post-content-call-to-action-anchor-interaction 0.28s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.post_content_call_to_action::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.post_content_call_to_action__text {
  transform: skew(15deg);
  max-width: 23ch;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  color: #ffc72e;
}
@media only screen and (min-width: 681px) {
  .post_content_call_to_action__text {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .post_content_call_to_action__text {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.post_content_call_to_action .triangle {
  transform: skew(15deg);
  display: inline-block;
  margin-left: 0.809rem;
  height: 1.077588rem;
  width: 1.077588rem;
}
.post_content_call_to_action .triangle__path {
  fill: #ffc72e;
}
.post_content_call_to_action .triangle__inner {
  fill: transparent;
}
.related_content {
  width: 100%;
}
* + .related_content {
  margin-top: 3.236rem;
}
.related_content__list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.related_content__item {
  flex: 0 1 50%;
  background-color: #fff;
}
.related_content__item:nth-child(even) {
  margin-left: 1.618rem;
}
.services_child_pages {
  flex: 1 1 auto;
  width: 100%;
}
* + .services_child_pages {
  margin-top: 3.236rem;
}
.services_child_pages__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1040px;
  padding-bottom: 3.236rem;
}
.services_child_pages__item {
  width: 100%;
}
@media only screen and (min-width: 681px) {
  .services_child_pages__item {
    flex: 0 0 49%;
  }
}
@media only screen and (max-width: 680px) {
  * + .services_child_pages__item {
    margin-top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .services_child_pages__item:nth-child(even) {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 681px) {
  .services_child_pages__item:nth-child(n+3) {
    margin-top: 2%;
  }
}
.services_child_pages__button {
  will-change: background-color, border-color, transform;
  transition-property: background-color, border-color, transform;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 2px;
  height: 100%;
  width: 100%;
  background-color: #1a384f;
  padding: 1.2135rem 2.156794rem 1.618rem;
}
.services_child_pages__button:active,
.services_child_pages__button:focus,
.services_child_pages__button:hover {
  transform: scale(1.01);
  background-color: #173145;
}
.services_child_pages__button::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: calc(0.809rem + 1px);
  right: calc(0.809rem - 2px);
  bottom: calc(0.809rem + 1px);
  left: calc(0.809rem - 2px);
  border: 1px solid #fff;
}
.services_child_pages__button::after {
  content: "";
  position: absolute;
  z-index: auto;
  top: calc(0.809rem - 4px);
  right: calc(0.809rem + 3px);
  bottom: calc(0.809rem - 4px);
  left: calc(0.809rem + 3px);
  border: 1px solid #fff;
}
.services_child_pages__button__text {
  flex: 1 1 auto;
  display: block;
  margin-top: 1.618rem;
  max-width: 38.5ch;
  text-align: left;
  font-weight: 400;
  font-size: 1.2996rem;
  font-size: var(--fz-ratio-power--two);
  color: #ffc72e;
}
@media only screen and (min-width: 681px) {
  .services_child_pages__button__text {
    font-size: 1.4161rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .services_child_pages__button__text {
    font-size: 1.5376rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.services_child_pages__button:active .services_child_pages__button__text,
.services_child_pages__button:focus .services_child_pages__button__text,
.services_child_pages__button:hover .services_child_pages__button__text {
  color: #ffd561;
}
.services_child_pages__content {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  border: 1.618rem solid #1a384f;
  background-color: #35546b;
  padding: 3.236rem 4vw;
}
@media only screen and (min-width: 681px) {
  .services_child_pages__content {
    padding: 4.854rem 1.618rem;
  }
}
.services_child_pages__content--is_visible {
  display: flex;
  animation: modal--open 0.14s;
}
.services_child_pages__content__inner {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}
.services_child_pages__content .reading_content {
  width: auto;
  max-width: 100%;
}
.services_child_pages__content__close,
.services_child_pages__content__close svg {
  height: 1.618rem;
  width: 1.618rem;
}
.services_child_pages__content__close {
  position: absolute;
  z-index: auto;
  top: 0.809rem;
  right: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .services_child_pages__content__close {
    top: 1.618rem;
  }
}
@media only screen and (min-width: 681px) {
  .services_child_pages__content__close {
    right: 1.618rem;
  }
}
.services_child_pages__content__close:active svg,
.services_child_pages__content__close:focus svg,
.services_child_pages__content__close:hover svg {
  fill: #ffd561;
}
.services_child_pages__content__close svg {
  fill: #ffc72e;
}
.articles_child_pages {
  width: 100%;
  margin-top: 3.236rem;
}
.articles_child_pages__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  width: 92%;
  max-width: 1330px;
  padding-bottom: 3.236rem;
}
.articles_child_pages__item {
  flex: 0 0 auto;
  width: 100%;
}
* + .articles_child_pages__item {
  margin-top: 1.618rem;
}
.articles_child_pages__anchor {
  will-change: background-color, border-color, box-shadow;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0 1px 1px rgba(255,255,255,0.1);
  box-shadow: 0 0 0 transparent;
  border: 1px solid #fff;
  border-left: 0;
  background-color: #fff;
  padding: 1.2135rem 0.809rem;
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__anchor {
    transform: skew(-15deg);
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__anchor {
    flex-direction: row;
  }
}
.articles_child_pages__anchor:active,
.articles_child_pages__anchor:focus,
.articles_child_pages__anchor:hover {
  box-shadow: 0 3px 6px rgba(255,255,255,0.1);
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__anchor {
    width: calc(100% - 3rem);
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__anchor {
    padding: 2.156794rem 2.156794rem 0.539333333333333rem 2.695588rem;
  }
}
.articles_child_pages__anchor::before {
  content: "";
  position: absolute;
  z-index: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__anchor > * {
    transform: skew(15deg);
  }
}
.articles_child_pages__heading_and_summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__heading_and_summary {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__heading_and_summary {
    align-items: flex-end;
  }
}
.articles_child_pages__heading_and_series_metadata {
  flex: 1 1 auto;
}
.articles_child_pages__heading {
  margin-top: -0.24em;
  max-width: 38.5ch;
  font-weight: 400;
  font-size: 1.14rem;
  font-size: var(--fz-ratio-power--one);
  color: #436782;
}
@media only screen and (min-width: 681px) {
  .articles_child_pages__heading {
    font-size: 1.19rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .articles_child_pages__heading {
    font-size: 1.24rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.articles_child_pages__heading:last-child {
  margin-bottom: -0.28em;
}
.articles_child_pages__series_metadata {
  display: block;
  margin-bottom: -0.46em;
  text-transform: uppercase;
  font-size: 0.769467528470299rem;
  font-size: var(--fz-ratio-power--minus-two);
  color: #fff;
}
* + .articles_child_pages__series_metadata {
  margin-top: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .articles_child_pages__series_metadata {
    font-size: 0.706164818868724rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .articles_child_pages__series_metadata {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.articles_child_pages__series_metadata__series_name {
  font-style: italic;
}
.articles_child_pages__summary {
  flex: 0 0 auto;
  margin-top: 1.077588rem;
  margin-left: auto;
  width: 100%;
  max-width: 48ch;
  font-style: italic;
  color: #fff;
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__summary {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__summary {
    margin-left: 1.618rem;
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__summary {
    max-width: 41ch;
  }
}
@media only screen and (min-width: 681px) {
  .articles_child_pages__summary {
    border-left: 1px solid #fff;
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__summary {
    border-left: 0;
  }
}
@media only screen and (min-width: 681px) {
  .articles_child_pages__summary {
    padding-left: 0.809rem;
  }
}
.articles_child_pages__definition_list {
  will-change: border-color;
  transition-property: border-color;
  transition-duration: 0.14s;
  transition-timing-function: ease-out;
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: -0.809rem;
  border-top: 1px solid #fff;
  padding-top: 0.539333333333333rem;
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__definition_list {
    display: none;
  }
}
* + .articles_child_pages__definition_list {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 1081px) {
  * + .articles_child_pages__definition_list {
    margin-top: 0.539333333333333rem;
  }
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__definition_list {
    margin-bottom: 0;
  }
}
.articles_child_pages__definition_list--with_heading {
  display: none;
}
@media only screen and (min-width: 1081px) {
  .articles_child_pages__definition_list--with_heading {
    display: flex;
  }
}
.articles_child_pages__definition_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.articles_child_pages__definition_item:not(:last-child) {
  margin-right: 0.809rem;
}
.articles_child_pages__definition_term,
.articles_child_pages__definition_description {
  font-size: 0.769467528470299rem;
  font-size: var(--fz-ratio-power--minus-two);
  text-transform: uppercase;
  color: #fff;
}
* + .articles_child_pages__definition_term,
* + .articles_child_pages__definition_description {
  margin-left: 0.269666666666667rem;
}
@media only screen and (min-width: 681px) {
  .articles_child_pages__definition_term,
  .articles_child_pages__definition_description {
    font-size: 0.706164818868724rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .articles_child_pages__definition_term,
  .articles_child_pages__definition_description {
    font-size: 0.650364203954214rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.articles_single__series_metadata {
  display: flex;
  flex-direction: row;
  align-items: center;
}
* + .articles_single__series_metadata {
  margin-top: 0.809rem;
}
.articles_single__series_metadata .vector__triangle_right {
  height: 0.539333333333333rem;
  width: 0.539333333333333rem;
}
.articles_single__series_metadata .vector__triangle_right__path {
  fill: #35546b;
}
.articles_single__series_metadata__text {
  font-size: 0.87719298245614rem;
  font-size: var(--fz-ratio-power--minus-one);
  color: rgba(255,255,255,0.8);
}
* + .articles_single__series_metadata__text {
  margin-top: 0;
}
* + .articles_single__series_metadata__text {
  margin-left: 0.269666666666667rem;
}
@media only screen and (min-width: 681px) {
  .articles_single__series_metadata__text {
    font-size: 0.840336134453782rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .articles_single__series_metadata__text {
    font-size: 0.806451612903226rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.articles_single__series_metadata__series_name {
  font-style: italic;
}
